import { DataTableQueryFilter } from '~common/components/table/QueryDataTable'
import {
  LogicalOperator,
  SortOrder,
  QueryFilter,
  FilterParameter,
  SortParameter,
  Node,
} from '~common/types'

export const convertOptionsToQuerystring = <T extends Node>(
  options: DataTableQueryFilter<T>,
): URLSearchParams => {
  const params = new URLSearchParams()
  params.append('pageIndex', `${(options.skip || 0) * (options.take || 12)}`)
  params.append('take', `${options.take || 12}`)
  if (options.sortColumn && options.sortDirection) {
    params.append('sortColumn', options.sortColumn as string)
    params.append('sortDirection', options.sortDirection as SortOrder)
  }
  if (options.filterOperator) {
    params.append('filterOperator', options.filterOperator)
  }
  if (options.filter) {
    params.append('filter', JSON.stringify(options.filter))
  }
  return params
}

export const convertOptionsToDataObject = <T extends Node>(
  options: DataTableQueryFilter<T>,
): any => {
  const formObj: any = {}
  formObj['pageIndex'] = [`${(options.skip || 0) * (options.take || 12)}`]
  formObj['take'] = [`${options.take || 12}`]
  if (options.sortColumn && options.sortDirection) {
    formObj['sortColumn'] = [options.sortColumn as string]
    formObj['sortDirection'] = [options.sortDirection as SortOrder]
  }
  if (options.filterOperator) {
    formObj['filterOperator'] = [options.filterOperator]
  }
  if (options.filter) {
    formObj['filter'] = [JSON.stringify(options.filter)]
  }
  return formObj
}

export const urlSearchOptions = <T>(request: Request): QueryFilter<T> => {
  const url = new URL(request.url)
  const sortColumn = url.searchParams.get('sortColumn') || undefined
  let sortDirection =
    (url.searchParams.get('sortDirection') as SortOrder | null) || undefined
  sortDirection =
    sortDirection && [SortOrder.Asc, SortOrder.Desc].includes(sortDirection)
      ? sortDirection
      : undefined
  let sort
  if (sortColumn) {
    sort = {
      [sortColumn as keyof T]: sortDirection || SortOrder.Desc,
    } as SortParameter<T>
  }
  const pageIndex = parseInt(url.searchParams.get('pageIndex') || '0', 10)
  const take = parseInt(url.searchParams.get('take') || '12', 10)
  let filterOperator =
    (url.searchParams.get('filterOperator') as LogicalOperator | null) ||
    undefined
  filterOperator =
    filterOperator &&
    [LogicalOperator.And, LogicalOperator.Or].includes(filterOperator)
      ? filterOperator
      : undefined
  let filterVal = url.searchParams.get('filter') || undefined
  let filter
  if (filterVal) {
    try {
      filter = JSON.parse(filterVal) as FilterParameter<T>
    } catch (err) {
      console.log('error parsing JSON', err)
      filter = undefined
    }
  }
  return {
    sort,
    skip: pageIndex,
    take,
    filterOperator,
    filter,
  }
}

export const formDataSearchOptions = async <T>(
  formData: FormData,
): Promise<QueryFilter<T>> => {
  const sortColumn = formData.get('sortColumn') || undefined
  let sortDirection =
    (formData.get('sortDirection') as SortOrder | null) || undefined
  sortDirection =
    sortDirection && [SortOrder.Asc, SortOrder.Desc].includes(sortDirection)
      ? sortDirection
      : undefined
  let sort
  if (sortColumn) {
    sort = {
      [sortColumn as keyof T]: sortDirection || SortOrder.Desc,
    } as SortParameter<T>
  }
  const pageIndex = parseInt(formData.get('pageIndex') || '0', 10)
  const take = parseInt(formData.get('take') || '12', 10)
  let filterOperator =
    (formData.get('filterOperator') as LogicalOperator | null) || undefined
  filterOperator =
    filterOperator &&
    [LogicalOperator.And, LogicalOperator.Or].includes(filterOperator)
      ? filterOperator
      : undefined
  let filterVal = formData.get('filter') || undefined
  let filter
  if (filterVal) {
    try {
      filter = JSON.parse(filterVal) as FilterParameter<T>
    } catch (err) {
      console.log('error parsing JSON', err)
      filter = undefined
    }
  }
  return {
    sort,
    skip: pageIndex,
    take,
    filterOperator,
    filter,
  }
}
